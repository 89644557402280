function App() {
  return (
    <div className="App">
      <h1>szkdev.com</h1>
      todo!
    </div>
  );
}

export default App;
